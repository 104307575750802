<template>
  <div class="align-items-center bg-dark d-flex flex-row justify-content-center vh-100">
    <b-col cols="12" md="8" lg="6" xl="4">
      <router-view />
    </b-col>
  </div>
</template>

<script>
export default {

}
</script>